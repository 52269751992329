.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, .9)), url('../biffwinsagain.jpeg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    
  }
  /* Styles for the buttons */
  .App-link {
    color: #FFFFFF; /* White text color for contrast */
    background-color: #e22132; /* Green background color */
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin-top: 30px;
  }
  
  .App-link:hover {
    background-color: #090809; /* Slightly darker green on hover */
    cursor: pointer;
  }
  
  /* Form and input styles for a more compact layout */
  .signup-form {
    margin-top: 20px;
  }
  
  .signup-form input[type='email'], .signup-form input[type='tel'] {
    padding: 10px;
    margin: 5px 0; /* Reduced top and bottom margin for compact layout */
    border: none;
    border-radius: 5px;
    width: 60%;
  }
  
  .signup-form div {
    margin: 10px 0; /* Reduced margin for the radio buttons container */
  }
  
  .signup-form label {
    margin: 0 10px;
    font-size: 1em;
    color: #fff;
  }
  
  .signup-form input[type='radio'] {
    margin-right: 5px;
  }
  
  /* Custom radio button styles for green theme */
  .signup-form input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    position: relative;
    background-color: #000000; /* Green background color */
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #fff; /* White border for contrast */
  }
  
  .signup-form input[type='radio']:checked:after {
    background-color: #e22132; /* Slightly darker green for checked state */
  }
 
  @keyframes marquee {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
  }
  
  .discord-text {
    color: white;
    font-size: 0.8em; /* adjust as needed */
    position: relative;
    bottom: 18px; /* adjust as needed */
    left: 10px;
  }
  
  .discord-link {
    position: relative;
    right: 0;
    bottom: 0;
  }
  .discord-link-container {
    position: absolute;
    top: 0;
    left: 20px;
  }
  
  
  /* Enhanced button styling */
  .button {
    color: #FFFFFF; /* White text color for readability */
    background-color: #1DA1F2; /* Twitter's brand color for instant recognition */
    border: none;
    border-radius: 20px; /* Rounded edges for a modern look */
    padding: 12px 24px;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 20px; /* Adjust as needed for layout */
    display: inline-flex; /* Allows for icon and text alignment */
    align-items: center; /* Centers items vertically */
    justify-content: center; /* Centers items horizontally */
  }
  
  .button:hover {
    background-color: #0d8bf2; /* A lighter shade of the brand color for hover state */
    cursor: pointer;
    transform: translateY(-2px); /* Slight lift effect on hover */
  }
  
  .button:focus {
    outline: none; /* Removes the default focus outline to maintain the design */
    box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.4); /* Custom focus style to enhance accessibility */
  }
  
  /* Optional: Adding a Twitter icon before the button text */
  .button svg {
    margin-right: 8px; /* Spacing between icon and text */
  }
  