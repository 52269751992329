.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 10px; /* Reduced top and bottom padding for a thinner header */
    background-color: #000000;
  }
  
  .discord-link-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
  
  .discord-link {
    display: flex;
    align-items: center;
  }
  
  .site-logo {
    color: white;
    flex-grow: 1;
    text-align: center;
    font-size: 1.5em; /* Keep as is or adjust based on your preference */
    text-decoration: none;
  }
  
  /* If the logo is still too large, consider adjusting the font-size specifically for the h1 within .site-logo */
  .site-logo h1 {
    margin: 0; /* Removes default margin */
    font-size: 1.2em; /* Adjust as needed for a thinner appearance */
  }
  
  .sign-in-button {
    color: #FFFFFF;
    background-color: #e22132;
    border: none;
    border-radius: 4px;
    padding: 10px 15px; /* Reduced padding for a thinner button */
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin-right: 20px; /* adjust as needed */

    text-align: right;
  }
  
  .sign-in-button:hover {
    background-color: #090809;
    cursor: pointer;
  }
  
  /* Optionally, adjust the Discord icon size if it's contributing to the thickness of the header */
  .discord-link img {
    height: auto; /* Keeps aspect ratio */
    width: 40px; /* Adjust width to make the icon smaller */
    margin-top: 14px; /* Adjust as needed to center the icon vertically */
  }
  
  @media screen and (max-width: 768px) {
    .discord-link img {
      display: none;
    }
  }
  
  