.card {
    background: white;
    min-height: 60vh;
    max-height: 60vh;
    min-width: 40vh;
    border-radius: 20px;
    overflow: auto;
    max-width: 40vh;

  }
.card-text {
color: black;
font-weight: 500;
}
.card-text-title {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center; /* add this line */
  }
  
  .card-text-title img {
    border-radius: 50%;
    width: 50px; /* adjust this value to your liking */
    height: 50px; /* adjust this value to your liking */
    margin-right: 10px;
  }
  
  .card-text-title h1 {
    font-size: .5em; /* adjust this value to your liking */
  }
.card-text-ev {
    color: red;
    font-weight: 700;
    font-size: 1.4em;
    }
    

.card-name {
    color: black;
    font-size: 1.3em;
    }
    
    @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
.card-center{
  display: flex;
  justify-content: space-between;
  animation: fadeIn 1.5s ease-in-out;

}

@media screen and (max-width: 768px) {
  .card-center {
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Add this line to create space between items */
  }
}
.call-to-action{
    margin-top: 30px;
    color: #FFFFFF;
    background-color: #e22132;
    border: none;
    border-radius: 4px;
    padding: 10px 15px; /* Reduced padding for a thinner button */
    font-weight: bold;
    transition: background-color 0.3s ease;
    text-align: right;
}
.call-to-action:hover {
    background-color: #090809;
    cursor: pointer;
  }

  .card-image {
    width: 50%;
    max-width: 300px;
    height: auto;
  }

  .chatbox {
    background: #f0f0f0;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    height: 50vh;
    overflow-y: auto;
    font-size: 0.8em; /* adjust this value to your liking */

  }
  
  .chat-message {
    background: #d0d0d0;
    border-radius: 5px;
    padding: 5px;
    margin: 5px 0;
    color: black;
  }
  
  .ai-typing {
    animation: typing 0.1s steps(40, end), blink-caret 0.45s step-end infinite;
  }
  
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  @keyframes blink-caret {
    50% { border-color: transparent; }
  }
  .see-details-button {
    color: #000000; /* Change text color to black */
    background-color: transparent; /* Make background transparent */
    border: none;
    border-bottom: 2px solid #e22132; /* Add red underline */
    border-radius: 0; /* Remove border radius */
    padding: 0; /* Remove padding */
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin-right: 20px; /* adjust as needed */
    text-align: right;
    text-align: inherit; /* remove this line */

  }
  
  .see-details-button:hover {
    background-color: transparent; /* Make hover background transparent */
    cursor: pointer;
  }