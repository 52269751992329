.enroll-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    margin: auto;
}
.enroll-input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #000;
    color: #000;
    width: 100%;
    margin: auto;
    margin-bottom: 20px; /* Adjust as needed */

}

.enroll-button {
    margin-top: 20px;
    color: #FFFFFF;
    background-color: #e22132;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    cursor: pointer;
    width: 100%;
}

.enroll-button:hover {
    background-color: #090809;
}

.close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
}

.button {
    color: #FFFFFF;
    background-color: #1DA1F2;
    border: none;
    border-radius: 20px;
    padding: 12px 24px;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.tweet-button{
    color: #FFFFFF; /* White text color for readability */
    background-color: #1DA1F2; /* Twitter's brand color for instant recognition */
    border: none;
    border-radius: 20px; /* Rounded edges for a modern look */
    padding: 12px 24px;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 20px; /* Adjust as needed for layout */
    display: inline-flex; /* Allows for icon and text alignment */
    align-items: center; /* Centers items vertically */
    justify-content: center; /* Centers items horizontally */
}

.tweet-button:hover {
    background-color: #0d8bf2; /* A lighter shade of the brand color for hover state */
    cursor: pointer;
}

.account-text{
    color: #000000;
    font-size: 1.5em;
    
}