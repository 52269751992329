.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    position: relative; /* add this line */
    background: white;
    padding: 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 300px; /* adjust as needed */
  }
  
.sign-up-input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #000; /* add border */
    color: #000; /* change text color */
    width: 90%; /* reduce width */
    margin: auto; /* center the input fields */
}
  
.sign-up-button {
    margin-top: 20px; /* add space above the button */
    color: #FFFFFF;
    background-color: #e22132;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    cursor: pointer;
    width: 100%;
}
  
  .sign-up-button:hover {
    background-color: #090809;
  }

  .close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }

  .button {
    color: #FFFFFF;
    background-color: #1DA1F2;
    border: none;
    border-radius: 20px;
    padding: 12px 24px;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }